import React,{useState} from "react";
import { Descriptions, Typography, Icon, Select } from "antd";
import "./GeneralInfo.scss";
import gql from "graphql-tag";
import { useQuery, useMutation } from "react-apollo";
import moment from "moment";
import { withTranslation } from "react-i18next";
const { Text } = Typography;
const { Option } = Select;

const GET_CAREGIVER_GENERAL_INFO = gql`
  query getCaregiverGeneralInfo($userId: ID!) {
    getUser(userID: $userId) {
      id
      firstName
      lastName
      birthdate
      email
      phoneNumber
      gender
      livingPlace
      address
      postalCode
      drivingLicense
      ownsCar
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(updateUserInput: $input) {
      id
      firstName
      lastName
      birthdate
      email
      phoneNumber
      gender
      livingPlace
      address
      postalCode
      drivingLicense
      ownsCar
    }
  }
`;

function getAgeFromBirthdate(birthdate) {
  const today = moment();
  const birthday = moment(birthdate * 1000);

  const duration = moment.duration(today.diff(birthday));
  const age = Math.trunc(duration.asYears());

  return age;
}


function Container(props){
  const {userId, t } = props

  const { loading, error, data } = useQuery(GET_CAREGIVER_GENERAL_INFO, {
    variables: { userId },
    fetchPolicy: "cache-and-network"
  });


  if (loading) return "Loading...";
  if (error) {
    console.log(error);
    return "Error :(";
  }

  if (data) {
    return <GeneralInfo user={data.getUser} t={t}/>;
  }


}

function GeneralInfo(props) {
  const { user, t } = props;

  const [firstName] = useState(user.firstName)
  const [lastName] = useState(user.lastName)
  const [email] = useState(user.email)
  const [age] = useState(getAgeFromBirthdate(user.birthdate))
  const [livingPlace, setLivingPlace] = useState(user.livingPlace)
  const [gender, setGender] = useState(user.gender)
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber)
  const [ownsCar, setOwnsCar] = useState(user.ownsCar)
  const [drivingLicense, setDrivingLicense] = useState(user.drivingLicense)
  const [postalCode, setPostalCode] = useState(user.postalCode)
  const [address, setAddress] = useState(user.address)

  const [displayGenderSelect, setDisplayGenderSelect] = useState(false);
  const [displayOwnsCarSelect, setDisplayOwnsCarSelect] = useState(false);
  const [displayLicenseSelect, setDisplayLicenseSelect] = useState(false);

  const [updateUser] = useMutation(UPDATE_USER);

  

  function handleInputChange(name, value) {
    
    updateUser({ variables: { input: { [name]: value } } });
  }

  function GenderSelect(){
    return(
      <Select style={{ width: 120 }} onChange={value=>{handleInputChange("gender",value); setGender(value); setDisplayGenderSelect(false)}}>
        <Option value="Male">Male</Option>
        <Option value="Female">Female</Option>
        <Option value="Other">Other</Option>
      </Select>
    )
  }

  function OwnsCarSelect(){
    return(
      <Select style={{ width: 120 }} onChange={value=>{handleInputChange("ownsCar",value === "yes" ? true : false); setOwnsCar(value === "yes" ? true : false); setDisplayOwnsCarSelect(false)}}>
        <Option value="yes">{t("yes")}</Option>
        <Option value="no">{t("no")}</Option>
      </Select>
    )
  }

  function DrivingLicenseSelect(){
    return(
      <Select style={{ width: 120 }} onChange={value=>{handleInputChange("drivingLicense",value === "yes" ? true : false); setDrivingLicense(value === "yes" ? true : false); setDisplayLicenseSelect(false)}}>
        <Option value="yes">{t("yes")}</Option>
        <Option value="no">{t("no")}</Option>
      </Select>
    )
  }

  

    return (
      <React.Fragment>
        <Descriptions>
          <Descriptions.Item label={t("first_name")}>
            <Text>{firstName}</Text>
          </Descriptions.Item>
          <Descriptions.Item label={t("last_name")}>
            <Text>{lastName}</Text>
          </Descriptions.Item>
          <Descriptions.Item label={t("email")}>
            <Text>
              {email}
              <Icon
                style={{ marginLeft: 5 }}
                type="info-circle"
                title="This information is hidden"
              />
            </Text>
          </Descriptions.Item>
          <Descriptions.Item label={t("age")}>
            <Text>{age}</Text>
          </Descriptions.Item>
          <Descriptions.Item label={t("living_place")}>
            <Text
              editable={{
                onChange: value => {handleInputChange("livingPlace", value); setLivingPlace(value)}
              }}
            >
              {livingPlace}
            </Text>
          </Descriptions.Item>
          
            <Descriptions.Item label={t("gender")}>
            {displayGenderSelect ? <GenderSelect /> :  <React.Fragment><Text>{gender}</Text><Icon type="edit" theme="twoTone" twoToneColor="#1890ff" style={{marginLeft:"5px"}} onClick={()=>setDisplayGenderSelect(true)} /></React.Fragment>}
              
            </Descriptions.Item>
          
          <Descriptions.Item label={t("phone_number")}>
            <Text
              editable={{
                onChange: value => {handleInputChange("phoneNumber", value); setPhoneNumber(value)}
              }}
            >              
              {phoneNumber}
            </Text>
            <Icon
              style={{ marginLeft: 5 }}
              type="info-circle"
              title="This information is hidden"
            />
          </Descriptions.Item>
          <Descriptions.Item label={t("own_car")}>
            
          {displayOwnsCarSelect ? <OwnsCarSelect /> :  <React.Fragment><Text>{ownsCar ? t("yes") : t("no")}</Text><Icon type="edit" theme="twoTone" twoToneColor="#1890ff" style={{marginLeft:"5px"}} onClick={()=>setDisplayOwnsCarSelect(true)} /></React.Fragment>}
             
          </Descriptions.Item>
          <Descriptions.Item label={t("driving_license")}>
            
            
          {displayLicenseSelect ? <DrivingLicenseSelect /> :  <React.Fragment><Text>{drivingLicense ? t("yes") : t("no")}</Text><Icon type="edit" theme="twoTone" twoToneColor="#1890ff" style={{marginLeft:"5px"}} onClick={()=>setDisplayLicenseSelect(true)} /></React.Fragment>}
             
          </Descriptions.Item>
          <Descriptions.Item label={t("postal_code")}>
            <Text
              editable={{
                onChange: value => {handleInputChange("postalCode", value); setPostalCode(value)}
              }}
            >
              {postalCode}
            </Text>
            <Icon
              style={{ marginLeft: 5 }}
              type="info-circle"
              title="This information is hidden"
            />
          </Descriptions.Item>
          <Descriptions.Item label={t("address")}>
            <Text
              editable={{
                onChange: value => {handleInputChange("address", value); setAddress(value)}
              }}
            >
              {address}
            </Text>
            <Icon
              style={{ marginLeft: 5 }}
              type="info-circle"
              title="This information is hidden"
            />
          </Descriptions.Item>
        </Descriptions>
      </React.Fragment>
    );
  
}

export default withTranslation()(Container);
