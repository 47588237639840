import gql from "graphql-tag";

export const LOGIN = gql`
  mutation logIn($email: String!, $password: String!) {
    logIn(email: $email, password: $password) {
      user {
        id
        firstName
        lastName
        email
        role
      }
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token) {
      id
      firstName
      lastName
      email
      role
    }
  }
`;

export const GET_CURRENT_USER_CLIENTS = gql`
  query getUserClients {
    currentUser {
      id
      clients {
        id
        type: __typename
        ... on Organization {
          name
        }
        ... on Region {
          name
        }
        ... on Location {
          name
        }
        ... on Patient {
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_TIME_LOGS = gql`
  query {
    timeLogEntries {
      id
      userID
      startTime
      endTime
    }
  }
`;

export const UPDATE_USER = gql`
mutation updateUser($input: UpdateUserInput!){
  updateUser(updateUserInput: $input){
    id
    firstName
    lastName
    bio
    hourlyRate
    hiringMode
    
  }
}
`

export const CREATE_WORK_EXPERIENCE = gql`
  mutation createWorkExperience($input: WorkExperienceInput!){
  createWorkExperience(workExperienceInput: $input){
    id    
  }
}
`

export const DELETE_WORK_EXPERIENCE = gql`
mutation($id: ID!){
  deleteWorkExperience(id:$id){
    id
  }
}
`

export const CREATE_STUDIES = gql`
  mutation($input: StudiesInput!){
  createStudies(studiesInput: $input){
    id
    user{
      firstName
    }
    startMonth
    startYear
    endMonth
    endYear
    studies
  }
}`

export const DELETE_STUDIES = gql`
mutation($id: ID!){
  deleteStudies(id:$id){
    id
  }
}
`

export const REGISTER_CHARACTERISTIC = gql`
  mutation($characteristicID: ID!){
  registerCharacteristic(characteristicID: $characteristicID){
    id
    name
  }
}`

export const LIST_CHARACTERISTICS = gql`
  query{
  listCharacteristics{
    id
    name
  }
}
` 

export const GET_USER = gql`
  query ($userID : ID!){
    getUser(userID: $userID) {
      id
      email
      role
      firstName
      lastName
      avatarUrl
      bio
      phoneNumber
      birthdate
      address
      postalCode
      livingPlace
      drivingLicense
      ownsCar
      gender
      studies{
        id
        endYear
        school
        studies
      }
      characteristics{
        id
        name
      }
      clients {
        id
        __typename
      }
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query {
    currentUser {
      id
      email
      role
      firstName
      lastName
      avatarUrl
      bio
      birthdate
      address
      postalCode
      livingPlace
      gender
      phoneNumber
      websiteUrl
      drivingLicense
      ownsCar
      characteristics{
        id
        name
      }
      clients {
        id
        __typename
      }
    }
  }
`;
