import React from "react";
import classnames from "classnames";
import styles from "./Card.module.scss";

const Card = (props) => {
  const { children, className, ...rest } = props;

  const classNames = classnames(
    styles.card,
    styles.shadowtrue,
    className
  );

  return (
    <div {...rest} className={classNames}>{children}</div>
  )
};

export default Card;