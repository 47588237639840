import React, {useState} from "react";
import { Tag, Select, Form, Button} from "antd";
import {useMutation} from "react-apollo"
import gql from "graphql-tag"


const DELETE_CHARACTERISTIC = gql`
mutation($id: ID!){
  deleteCharacteristic(id:$id)
}
`

const UPDATE_CHARACTERISTICS = gql`
  mutation($ids: [ID!]!) {
    updateCharacteristics(ids: $ids)
  }
`;

function Characteristics(props){

    
    const { userCharacteristics, allCharacteristics} = props

    const userCharacteristicsIDs = userCharacteristics.map(char => char.id)

    const characteristicsToSelect = allCharacteristics.filter(char=>!userCharacteristicsIDs.includes(char.id)) // Remove characteristics that the user already has
  
    const [characteristics, setCharacteristics] = useState(userCharacteristics)
    const [selectedCharacteristics, setSelectedCharacteristics] = useState()

    const [deleteCharacteristic] = useMutation(DELETE_CHARACTERISTIC)
    const [updateCharacteristics] = useMutation(UPDATE_CHARACTERISTICS)
   

    return(
      <div>
        {characteristics.map(characteristic=><Tag key={characteristic.id} style={{ marginBottom: "8px" }} closable onClose={()=>{deleteCharacteristic({variables: {id: characteristic.id}}); setCharacteristics(characteristics.filter(char=>char !== characteristic))}}>
        {characteristic.name}
      </Tag>)}
      <div style={{ marginTop: "8px" }}>
      <Form layout="vertical" hideRequiredMark onSubmit={e => {
        e.preventDefault();          

          let characteristicsToUpdate = characteristics.map(char=>char.id)
          
          if(selectedCharacteristics){
            characteristicsToUpdate = characteristicsToUpdate.concat(selectedCharacteristics)
          }

          updateCharacteristics({variables:{ids: characteristicsToUpdate}})

          window.location.reload();
        }}>
      <Form.Item label="Add more">        
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            size="default"
            placeholder="Select a characteristic"
            onChange={(value)=>setSelectedCharacteristics(value)}
          >
            {characteristicsToSelect.map(characteristic=><Select.Option key={characteristic.id} >{characteristic.name}</Select.Option>)}
          </Select>
          
          <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: "10px"}}
            >
              Update characteristics
            </Button>
          
          
        
      </Form.Item>
    </Form>
      </div>
    </div>
    )

}

export default Characteristics;