import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Route from "./components/Route";
import Login from "./views/Login";
import Logout from "./views/Logout";
import Home from "./views/Home/Home";
import NotFound from "./views/NotFound";
import Profile from "./views/Profile/Profile";
import Shell from "./Shell";

const App = () => (
  <Router>
    <Shell>
      <Switch>
        {/* <Route secure exact path="/" component={Home} /> */}
        <Route exact path="/" component={Login} />
        <Route secure path="/logout" exact component={Logout} />
        <Route secure path="/home" exact component={Home} />
        <Route secure path="/profile" component={Profile} />
        <Route render={NotFound} />
      </Switch>
    </Shell>
  </Router>
);

export default App;
