import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import InfoCard from "./InfoCard";

const GET_CURRENT_USER = gql`
  query getCurrentUser {
    currentUser {
      id
      email
      firstName
      lastName
      role
      avatarUrl
    }
  }
`;

function Container({...rest}) {
  return (
    <Query query={GET_CURRENT_USER}>
      {({ loading, error, data }) => {
        if (loading) return <p>loading...</p>;
        if (error) return <p>Error :(</p>;

        return (
          <InfoCard user={data.currentUser} {...rest} />
        );
      }}
    </Query>
  );
}

export default Container;