import React from "react";
import "./Description.scss";
import { Card, Typography, Divider } from "antd";

import { Link } from "react-router-dom";

function Description(props) {
  const { Title, Text } = Typography;
  const { cardHeader, children, cardHeaderTags, linkName, linkUrl } = props;

  return (
    <Card style={{ width: "100%" }} className={"tb-margin-bottom-large"}>
      <Title level={4}>
        {cardHeader} {cardHeaderTags}{" "}
        {linkName && (
          <Link
            style={{ float: "right", fontSize: "14px", fontWeight: "normal" }}
            to={linkUrl}
          >
            <Text style={{ color: "#6d6eed" }}>{linkName}</Text>
          </Link>
        )}
      </Title>

      <Divider />
      {children}
    </Card>
  );
}

export default Description;
