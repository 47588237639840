import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import DefaultAvatar from "./account.svg";
import styles from "./Banner.module.scss";
import Text from "../../../Type/Text";
import { Spacers} from "../../../Layout/Layout";
import UpdatePictureButton from "../../../Avatar/UpdatePictureButton";

function ProfileBanner(props) {
  const {
    t,
    firstName,
    lastName,
    role = t("caregiver"),
    url,
    editPicture
  } = props;

  const src = url || DefaultAvatar;

  const [displayButton, setDisplayButton] = useState("");
  const [opacity, setOpacity] = useState("1");

  return (
    <div className={`${styles.tb_l_spacer__large}`}>
      {editPicture ? (
        <div
          className={`${styles.tb_l_avatar_block_main}`}
          style={{ backgroundImage: `url(${src})`, opacity: opacity }}
          alt={`${firstName} avatar`}
          onMouseOver={() => {
            setDisplayButton(true);
            setOpacity("0.5");
          }}
          onMouseOut={() => {
            setDisplayButton(false);
            setOpacity("1");
          }}
        >
          {displayButton ? <UpdatePictureButton /> : ""}
        </div>
      ) : (
        <div
          className={`${styles.tb_l_avatar_block_main}`}
          style={{ backgroundImage: `url(${src})`, opacity: opacity }}
          alt={`${firstName} avatar`}
        />
      )}

      <div className={`${styles.tb_l_profile__name}`}>
        <Text bottom={Spacers.sBMedium}>{firstName + " " + lastName}</Text>
        <span className={`${styles.tb_l_profile__name_jtitle}`}>{role}</span>
      </div>
      <ul style={{ fontWeight: "lighter", fontSize: 17 }}>{props.children}</ul>
    </div>
  );
}

export default withTranslation()(ProfileBanner);
