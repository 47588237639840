import React from "react";
import LogoSVG from "./logo-new-tibu.svg";
import { logo } from "./Logo.module.scss";

function Logo(){
  return (
    <div className={logo}>
      <img src={LogoSVG} alt="Logo" />
    </div>
  )
}

export default Logo;
