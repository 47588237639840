import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { isMobile } from 'react-device-detect'
;
import InfoCardContainer from "../../components/User/InfoCard/Container";
import {
  Spacer,
  Spacers,
  Tgrid,
  tbGrids
} from "../../components/Layout/Layout";

import { Typography } from "antd";
import TimeRegistrationForm from "../../components/TimeRegistrationForm";
import TimeSheet from "../../components/TimeSheet";
import TimeLogStats from "../../components/TimeLogStats";
import { Wrapper } from "../../components/Wrapper";
import Header from "../../components/Header";

const GET_CURRENT_USER = gql`
  query getCurrentUser {
    currentUser {
      id
      email
      firstName
      lastName
      role
      avatarUrl
    }
  }
`;

const { Title } = Typography;

export default function Home() {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: "cache-first"
  });

  return (
    <>
      <Header />
      <Wrapper style={{ paddingTop: 30 }}>
        {!isMobile && <Tgrid gr={tbGrids.grid20}>
          <InfoCardContainer />
        </Tgrid>}
        <Tgrid gr={isMobile ? tbGrids.grid100 : tbGrids.grid80}>
          <Spacer spacer={!isMobile && Spacers.sLargeL}>
            <Title level={3} className="tb-margin-bottom-medium">
              {t("welcome_back")}
              {!loading && !error && data && `, ${data.currentUser.firstName}`}
            </Title>
            <TimeLogStats />
            <TimeRegistrationForm />
            <TimeSheet />
          </Spacer>
        </Tgrid>
      </Wrapper>
    </>
  );
}
