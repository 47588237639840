import React,{useState} from "react"
import { Input, Typography,Button} from "antd"
import { useMutation} from "react-apollo";
import gql from "graphql-tag"

const {Text} = Typography;
const {TextArea} = Input

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(updateUserInput: $input) {
      id
      bio
    }
  }
`;

function Bio(props){
    const { bioDescription = "", renderEdit} = props

    const [updateBio] = useMutation(UPDATE_USER)
    const [description,setDescription] = useState(bioDescription || "")

    return(renderEdit ? <React.Fragment><TextArea defaultValue={description} autosize={{minRows:6}} onChange={(e)=>{setDescription(e.target.value)}}></TextArea><Button type="primary" style={{marginTop: "10px"}} onClick={()=>{updateBio({variables:{input:{bio:description}}}); window.location.reload()}}>Edit</Button></React.Fragment>:description && description.split("\n").map((paragraph,index)=><p key={index}><Text >{paragraph}</Text></p>))
}

export default Bio;