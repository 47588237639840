import gql from "graphql-tag";

export default {
  User: {
    BasicInfo: gql`
      fragment BasicInfo on User {
        id
        email
        firstName
        lastName
        role
        avatarUrl
      }
    `
  }
};