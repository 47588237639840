import React from "react";
import { Spacers, Fonts } from "../../../components/Layout/Layout";

const Text = props => {
  const {
    children,
    spacer = Spacers.default,
    font = Fonts.default,
    bottom = Spacers.default,
    ...rest
  } = props;

  return <p {...rest} className={`${spacer} ${font} ${bottom}`}>{children}</p>;
};

export default Text;
