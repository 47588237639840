import React from "react";
import { withTranslation } from 'react-i18next';
import { Query } from "react-apollo";
import { GET_USER } from "../../../graphql";
import ProfileBanner from "../../UI/Profile/Banner";
import ProfileEntry from "../../UI/Profile/Entry";
import {Link} from "react-router-dom";
import './Container.scss';

const Container = ({t, userID,isOwn, ...rest}) => (
  <Query query={GET_USER} variables={{userID}} fetchPolicy="cache-and-network" >
    {({ loading, error, data }) => {
      if(loading) return <p>Loading...</p>;
      if(error) return <p>Error :(</p>;

      if(data.getUser){
        const {id,firstName,lastName,email, studies, characteristics, livingPlace} = data.getUser

        let lastStudy
        if(studies && studies.length){
          lastStudy = studies.reduce((last,current)=>last.endYear ? (last.endYear > current.endYear ? last : current) : last)
        }

        let briefCharacteristics = []
        if(characteristics){
          briefCharacteristics = characteristics.slice(0,3)
        }
        
        return (
          <div class="showHide">
            <ProfileBanner firstName={firstName} lastName={lastName}  {...rest}>
              <ProfileEntry icon="Location">{livingPlace}</ProfileEntry>
              <ProfileEntry icon="Mail">{email}</ProfileEntry>
              {lastStudy ? <ProfileEntry icon="Language">{lastStudy.studies} </ProfileEntry> : "" }             
              {briefCharacteristics.map(({id,name})=>(<ProfileEntry key={id}>{name}</ProfileEntry>))}
              {isOwn ? (<ProfileEntry icon="Edit"><Link to={`/profile`} >{t('edit_profile')}</Link></ProfileEntry>) : (<ProfileEntry icon="Share"><Link to={`/profile/${id}`} >See profile</Link></ProfileEntry>)}
            </ProfileBanner>
          </div>
        );
      }
        
      
    }}
  </Query>
);

export default withTranslation()(Container);