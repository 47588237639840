import React from "react";
import {useMutation} from "react-apollo"
import { Timeline, Icon, Typography } from "antd";
import { withTranslation } from 'react-i18next';
import AddWorkExperienceForm from "../AddWorkExperienceForm";
import "./WorkExperience.scss";
import gql from "graphql-tag";
const { Text } = Typography;

export const DELETE_WORK_EXPERIENCE = gql`
mutation($id: ID!){
  deleteWorkExperience(id:$id){
    id
  }
}
`

function WorkExperience(props){
  
    const { workExperienceEntries,t } = props;

    const months = {
      0: t("Jan"),
      1: t("Feb"),
      2: t("Mar"),
      3: t("Apr"),
      4: t("May"),
      5: t("Jun"),
      6: t("Jul"),
      7: t("Aug"),
      8: t("Sep"),
      9: t("Oct"),
      10: t("Nov"),
      11: t("Dic"),
      12: t("Dic") //TODO: Delete this when months are changed in the Database
    };

    const [deleteWorkExperience] = useMutation(DELETE_WORK_EXPERIENCE);

    return(
      <Timeline >
        <Timeline.Item
        dot={
          <Icon
            type="edit"
            theme="twoTone"
            twoToneColor="#6d6eed"
            style={{ fontSize: "16px" }}
          />
        }
      >
        <Text strong>{t("new_experience")}</Text>
        <AddWorkExperienceForm></AddWorkExperienceForm>
      </Timeline.Item>
      {workExperienceEntries.map(workEntry=>{
        return(
          
          <Timeline.Item
            dot={<Icon type="star" theme="twoTone" twoToneColor="#6d6eed" />}
            color="green"
            key={workEntry.id}
          >
            <Text style={{ color: "#6d6eed" }}>
              {workEntry.position}
            </Text><Icon type="delete" title="Delete work experience" onClick={()=>{deleteWorkExperience({variables:{id:workEntry.id}}); window.location.reload()}} style={{marginLeft:5}}/>
            <br />
            <Text strong>{workEntry.company}</Text>{" "}
            <br />
            <Text className={"tb-margin-bottom-medium"} type="secondary">
            {workEntry.startMonth ? months[workEntry.startMonth] : ""} {workEntry.startYear} -{" "}
                {workEntry.endMonth ? months[workEntry.endMonth] : ""}{" "}
                {workEntry.endYear ? workEntry.endYear : t('present')}
            </Text>        
          </Timeline.Item>     
        )        
      })}
      </Timeline>
       
    )
}

export default withTranslation()(WorkExperience);