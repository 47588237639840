import React from "react";
import "./type.scss";

export const TextSize = {
  default: "tb-l-text-size--default",
  sm: "tb-l-text-size--sm",
  lg: "tb-l-text-size--lg"
};

export const TextBold = {
  default: "tb-l-text--default",
  semibold: "tb-l-text--semibold",
  bold: "tb-l-text--bold"
};

export const TextAlign = {
  default: "tb-l-text-align--default",
  right: "tb-l-text-align--right",
  justify: "tb-l-text-align--justify"
};
export const TextFamily = {
  default: "tb-l-text-family--default"
};

export const Type = ({
  tag = "span",
  size = TextSize.default,
  boldness = TextBold.default,
  family = TextFamily.default,
  children,
  className = "",
  align = TextAlign.default,
  ...rest
}) => {
  const Tag = `${tag}`;
  const classNames = `tb-l-text ${size} ${boldness} ${align} ${className}`;
  return (
    <Tag className={classNames} {...rest}>
      {children}
    </Tag>
  );
};
