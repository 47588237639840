import React from "react";
import { withTranslation } from "react-i18next";
import WorkExperienceContainer from "../../components/WorkExperience/Container";
import StudiesContainer from "../../components/Studies/Container";
import { GET_CURRENT_USER } from "../../graphql";
import { Query } from "react-apollo";
import CharacteristicsContainer from "../../components/Characteristics/Container";
import InfoCardContainer from "../../components/User/InfoCard/Container";
import Bio from "../../components/Bio/Bio";
import { Icon } from "antd";
import { Wrapper } from "../../components/Wrapper";
import { isMobile } from 'react-device-detect';

import {
  Rowz,
  Spacer,
  Spacers,
  Tgrid,
  tbGrids
} from "../../components/Layout/Layout";

import Description from "../../components/Description";
import GeneralInfo from "../../components/GeneralInfo";
import Header from "../../components/Header";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderUpdateForm: false,
      renderAddWorkExperienceForm: false,
      renderBioForm: false,
      renderStudiesForm: false,
      renderCharacteristicsForm: false
    };
  }

  displayUpdateForm() {
    this.setState({ renderUpdateForm: true });
  }

  displayStudiesForm() {
    this.setState({ renderStudiesForm: true });
  }
  displayBioForm() {
    this.setState({ renderBioForm: true });
  }

  displayCharacteristicsForm() {
    this.setState({ renderCharacteristicsForm: true });
  }

  displayAddWorkExperienceForm() {
    this.setState({ renderAddWorkExperienceForm: true });
  }

  render() {
    const { t } = this.props;

    return (
      <Query query={GET_CURRENT_USER}>
        {({ data, error, loading }) => {
          if (loading) return "loading...";
          if (error) return "Error :(";
          
          if (data && data.currentUser) {
            const currentUserId = data.currentUser.id;
            const bio = data.currentUser.bio;
            return (
              <>
                <Header />
                <Wrapper style={{ paddingTop: 30 }}>
                  {!isMobile && <Tgrid gr={tbGrids.grid20}>
                    <InfoCardContainer editPicture />
                  </Tgrid>}
                  <Tgrid gr={isMobile ? tbGrids.grid100 : tbGrids.grid80}>
                    <Spacer spacer={!isMobile && Spacers.sLargeL}>
                      <Rowz bottom={Spacers.sBMedium}>
                        <Description cardHeader={t("profile_details")}>
                          <GeneralInfo userId={currentUserId} />
                        </Description>
                      </Rowz>
                      <Rowz bottom={Spacers.sBMedium}>
                        <Description
                          cardHeader={
                            <React.Fragment>
                              Bio
                              <Icon
                                type="edit"
                                style={{ marginLeft: "10px" }}
                                onClick={() => this.displayBioForm()}
                              />
                            </React.Fragment>
                          }
                        >
                          <Bio
                            bioDescription={bio}
                            renderEdit={this.state.renderBioForm}
                          />
                        </Description>
                      </Rowz>

                      <Rowz bottom={Spacers.sBMedium}>
                        <Description cardHeader={t("characteristics")}>
                          <CharacteristicsContainer userID={currentUserId} />
                        </Description>
                      </Rowz>

                      <Rowz bottom={Spacers.sBMedium}>
                        <Description cardHeader={t("work_experience")}>
                          <WorkExperienceContainer
                            userID={currentUserId}
                            showDelete={this.state.renderStudiesForm}
                          />
                        </Description>
                      </Rowz>

                      <Rowz bottom={Spacers.sBMedium}>
                        <Description cardHeader={t("studies")}>
                          <StudiesContainer
                            userID={currentUserId}
                            showDelete={this.state.renderStudiesForm}
                          />
                        </Description>
                      </Rowz>
                    </Spacer>
                  </Tgrid>
                </Wrapper>
              </>
            );
          }
        }}
      </Query>
    );
  }
}

export default withTranslation()(Profile);
