import React from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import styles from "./UpdatePictureButton.module.scss";

const UPDATE_AVATAR = gql`
  mutation updateAvatar($file: Upload!) {
    updateAvatar(file: $file) {
      filename
    }
  }
`;

class UpdatePictureButton extends React.Component {
  render() {
    return (
      <Mutation mutation={UPDATE_AVATAR}>
        {(updateAvatar, { data, loading, called, error }) => {
          if(called){
            window.location.reload();            
          }

          return (
            
            <label className={`${styles.file_container}`}>
              <input
                type="file"
                onChange={({
                  target: {
                    validity,
                    files: [file]
                  }
                }) => validity.valid && updateAvatar({ variables: { file } })}
              />
              Edit
            </label>
            
          )
        }}
      </Mutation>
    );
  }
}

export default UpdatePictureButton;
