import React from "react";
import classnames from "classnames";
import { wrapper } from "./Wrapper.module.scss";

export default function Wrapper({ children, className, ...rest }) {
  return (
    <div {...rest} className={classnames(wrapper, className)}>
      {children}
    </div>
  );
}