import React from "react";
import { Query } from "react-apollo";
import WorkExperience from "./WorkExperience";
import gql from "graphql-tag"

export const GET_USER_WORK = gql`
  query($userID: ID!) {
    getUser(userID:$userID){
      id
      workExperience{
        id
        startMonth
        startYear
        endMonth
        endYear
        company
        position
        present
      }
    }
  }
`;

const compareDates = (a,b) => {
  if(a.endYear){
   if(b.endYear){
     if(a.endYear > b.endYear){
       return 1
     }else if(a.endYear < b.endYear){
       return -1
     }else{
       return 0
     }
   }else{
     return -1
   }
  }else{
    if(b.endYear){
      return 1
    }else{
      return 0
    }
  }
}


const Container = ({userID, ...rest}) => (
    <Query query={GET_USER_WORK} variables={{userID}} >
    {
      ({error,loading, data})=>{
        if(error) return "error :("
        if(loading) return "loading"
        if(data.getUser){

          let workExperience = data.getUser.workExperience;

          if(workExperience){
            workExperience = workExperience.sort(compareDates)
            workExperience = workExperience.reverse()
          }
          
          return(
            <WorkExperience workExperienceEntries={workExperience || []} {...rest}/>
          )
        }
      }
    }
    </Query>
);

export default Container;




