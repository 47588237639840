import React from "react";
import { useQuery } from "react-apollo";
import Characteristics from "./Characteristics";
import gql from "graphql-tag";

const GET_USER_CHARACTERISTICS = gql`
  query($userID: ID!) {
    getUser(userID: $userID) {
      id
      characteristics {
        id
        name
      }
    }
  }
`;

const LIST_CHARACTERISTICS = gql`
  query {
    listCharacteristics {
      id
      name
    }
  }
`;

function Container({ userID }) {
  const { loading, error, data: userData } = useQuery(GET_USER_CHARACTERISTICS, {
    variables: { userID }
  });
  const {
    loading: loadingAll,
    error: errorAll,
    data: allData
  } = useQuery(LIST_CHARACTERISTICS);


  if (loading || loadingAll) {
    return "Loading";
  }
  if (error || errorAll) {
    return "error";
  }
  if (allData) {    
    return <Characteristics userCharacteristics={userData.getUser.characteristics} allCharacteristics={allData.listCharacteristics} />;
  }

 
}

export default Container;
