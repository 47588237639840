import React from 'react';
import { Card, Typography, Divider } from 'antd';

const { Title } = Typography;

export default function Panel(props) {
	const { title, children } = props;
	return (
		<Card className="tb-margin-bottom-large" style={{ width: '100%' }}>
			{title && (
				<>
					<Title level={4}>{title}</Title>
					<Divider />
				</>
			)}
			{children}
		</Card>
	);
}
