import React from "react";
import classnames from "classnames";
import "./Layout.scss";

export const BoxBorderStyle = {
  default: "tb-card-border--default",
  light: "tb-card-border--light",
  thick: "tb-card-border--thick"
};
export const InputIcons = {
  set: "input_icons",
  cal: "cal",
  default: "arrow"
};
export const ButtonStyle = {
  default: "tb_l_button",
  primary: "tb_l_button__primary",
  link: "tb_l_button__link",
  positive: "posi",
  negative: "neg",
  delete: "delete",
  ghostOn: "ghost_on",
  ghost: "tb_l_button__interactive"
};

export const BoxStyle = {
  default: "tb-card--default",
  doubleSpace: "tb-card--double-space",
  noSpace: "tb-card--no-space",
  spacer: "tb-card--default--bottom-spacer",
  flat: "tb-card--default--no-margin"
};
export const Fonts = {
  brand: "tb_l_dates_ex",
  default: "default",
  bold: "bold"
};
export const Spacers = {
  sLarge: "tb_l_spacer__large",
  sLargest: "tb_l_spacer__largest",
  sMedium: "tb_l_spacer__medium",
  sBMedium: "tb_l_spacer_b__medium",
  sLargeL: "tb_l_spacer_large__left",
  sLargeR: "tb_l_spacer__large__right",
  sLargerL: "tb_l_spacer_larger__left",
  sLargerR: "tb_l_spacer__larger__right",
  sSmallL: "tb_l_spacer_small__left",
  sSmallR: "tb_l_spacer_small__right",
  default: "tb_l_spacer",
  sSmall: "tb_l_spacer__small",
  sSmaller: "tb_l_spacer__smaller",
  sMediumB: "tb_l_spacer_b__medium",
  resetR: "tb_l_spacer__large__rreset",
  resetl: "tb_l_spacer__large__lreset",
  resett: "tb_l_spacer__large__treset",
  resetb: "tb_l_spacer__large__breset"
};

export const tbGrids = {
  grid10: "tb_col_10",
  grid20: "tb_col_20",
  grid30: "tb_col_30",
  grid40: "tb_col_40",
  grid50: "tb_col_50",
  grid60: "tb_col_60",
  grid70: "tb_col_70",
  grid80: "tb_col_80",
  grid90: "tb_col_90",
  default: "tb_col_default",
  inlineBlockSpace: "tb_col_inline_block_space"
};

export const Floaters = {
  left: "tb_l_pull_left",
  right: "tb_l_pull_right",
  none: "tb_l_pull_none",
  center: "centered"
};

export const Rowz = ({
  children,
  bottom = Spacers.resetb,
  float = Floaters.none,
  extraClassNames = []
}) => {
  const classNames = classnames(
    "row",
    ...extraClassNames,
    `${bottom} ${float}`
  );
  return <div className={classNames}>{children}</div>;
};
export const Wrapper = ({ children, fullWidth = true, className, ...rest }) => {
  const classNames = classnames(
    "wrapper",
    fullWidth && "tb-wrapper--fullwidth",
    className
  );

  return (
    <div {...rest} className={classNames}>
      {children}
    </div>
  );
};

export const Flex = ({ children, lastElRight }) => {
  const classNames = `flex ${lastElRight ? "flex-align-right" : ""}`;
  return <div className={classNames}>{children}</div>;
};

export const Tgrid = ({
  children,
  gr = tbGrids.default,
  spacer = Spacers.sSmall,
  bottom = Spacers.resetb,
  float = Floaters.left
}) => {
  const classNames = `${gr} ${spacer} ${bottom} ${float}`;
  return <div className={classNames}>{children}</div>;
};

export const Spacer = ({ children, spacer = Spacers.sMedium }) => {
  const classNames = `${spacer}`;
  return <div className={classNames}>{children}</div>;
};

export const Card = ({
  children,
  borderStyle = BoxBorderStyle.default,
  boxStyle = BoxStyle.noSpace,
  spacer = Spacers.default,
  fullWidth = false,
  className,
  ...rest
}) => {
  const classNames = classnames(
    "card",
    borderStyle,
    boxStyle,
    spacer,
    fullWidth && "tb-card--fullwidth",
    className
  );

  return (
    <div {...rest} className={classNames}>
      {children}
    </div>
  );
};
