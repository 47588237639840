import React from "react";
import { Link } from "react-router-dom";
import "./TbAvatar.scss";
import { Avatar, Button, Dropdown, Icon, Menu, Row, Col } from "antd";

function TbAvatar(props) {
  const {
    firstName,
    lastName,
    url
  } = props;

  const lang = localStorage.getItem("lang") || "nl";
  const langToSet = lang === "nl" ? "en" : "nl";

  function toggleLang() {
    localStorage.setItem("lang", langToSet);
    window.location.reload();
  }

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link to="/profile">View profile</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3"><Link to="/logout">Sign out</Link></Menu.Item>
    </Menu>
  );
  return (
    <Row className={"tb_l_avatar_block"}>
      <Col xs={12} sm={12} md={12} lg={4}>
        <Button
          onClick={toggleLang}
          style={{
            marginRight: 15,
            padding: 5,
            lineHeight: 1
          }}
        >
          {langToSet}
        </Button>
      </Col>
      <Col xs={11} sm={11} md={11} lg={3}>
        <Button type="link" to="/profile" style={{ padding: "0" }}>
          <Link to="/home">
            <Avatar
              className="tb_l_avatar"
              style={{ backgroundColor: "#6D6EED" }}
              icon="user"
              src={url}
            />
          </Link>
        </Button>
      </Col>
      <Col xs={0} sm={0} md={0} lg={13} style={{textAlign: "right"}}>
        {" "}
        <Button
          type="link"
          to="/profile"
          style={{ padding: "0", textAlign: "right" }}
        >
          <div className="tb_l_avatar_block__text">
            <span>{`${firstName} ${lastName}`}</span>
          </div>
        </Button>
      </Col>
      <Col xs={1} sm={1} md={1} lg={4} style={{ textAlign: "right" }}>
        <Dropdown overlay={menu} trigger={["click"]}>
          <Icon type="menu" style={{ position: "relative", top: "6px" }} />
        </Dropdown>
      </Col>
    </Row>
  );
}

export default TbAvatar;
