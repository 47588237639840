import React, { useMemo } from 'react';
import { Typography, Icon, Tag } from 'antd';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';

const { Text } = Typography;

const GET_CURRENT_USER_TIME_LOGS = gql`
	query getUserTimeLogs($startDate: Date, $endDate: Date) {
		currentUser {
			id
			role
			timeLogEntries(startDate: $startDate, endDate: $endDate) {
				id
				organization {
					id
					name
				}
				job{
					id
				}
				status
				approved
				startTime
				endTime
			}
		}
	}
`;

export default function TimeLogStats() {
	// We use '.valueOf()' because the backend is expecting a timestamp
	const startDate = useMemo(() => moment().subtract(6, 'months').valueOf(), []);
	const { loading, error, data } = useQuery(GET_CURRENT_USER_TIME_LOGS, {
		fetchPolicy: 'cache-first',
		variables: {
			startDate
		}
	});

	const timeLogStats = useMemo(() => {
		if (!loading && !error && data) {
			return data.currentUser.timeLogEntries.reduce(
				(stats, entry) => {
					switch (entry.status) {
						case 'PENDING':
							stats.pending++;
							return stats;
						case 'APPROVED':
							stats.approved++;
							return stats;
						case 'DECLINED':
							stats.declined++;
							return stats;
						default:
							return stats;
					}
				},
				{
					declined: 0,
					approved: 0,
					pending: 0,
				}
			);
		}

		return {};
	}, [loading, data]);

	return (
    <Text type="secondary" className="tb-margin-bottom-large">
      <span style={{ display: 'inline-block', marginRight: '8px' }}>
        <Icon
          type="notification"
          style={{ marginRight: '10px', display: 'inline-block' }}
          theme="twoTone"
          twoToneColor="#6d6eed"
        />
        Your quick stats:
      </span>
      <Tag style={{ marginBottom: '8px' }}>{timeLogStats.pending} Pending time logs</Tag>
      <Tag style={{ marginBottom: '8px' }} color="green">
        {timeLogStats.approved} Approved time logs
      </Tag>
      <Tag style={{ marginBottom: '8px' }} color="red">
        {timeLogStats.declined} Declined time logs
      </Tag>
    </Text>
	);
}
