import React, { useState, useEffect } from "react";
import { useMutation } from "react-apollo";
import { Button, Row, Col, Form, Input, DatePicker } from "antd";
import "./AddWorkExperienceForm.scss";
import gql from "graphql-tag";
import { withTranslation } from "react-i18next";

export const CREATE_WORK_EXPERIENCE = gql`
  mutation createWorkExperience($input: WorkExperienceInput!) {
    createWorkExperience(workExperienceInput: $input) {
      id
    }
  }
`;

function AddWorkExperienceForm({ t }) {
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [startMonth, setStartMonth] = useState();
  const [startYear, setStartYear] = useState();
  const [endMonth, setEndMonth] = useState();
  const [endYear, setEndYear] = useState();

  const [validDates, setValidDates] = useState({
    validateStatus: "success",
    errorMsg: null
  });

  // Checks that start date is before end date
  function checkDates() {
    if (endYear) {
      // End year earlier than start year
      if (endYear < startYear) {
        setValidDates({
          validateStatus: "error",
          errorMsg: "End of work cannot be before start of work"
        });
        return false;
      } else if (startMonth && endMonth) {
        // Same year but end month is earlier than start month
        if (endYear === startYear) {
          if (endMonth < startMonth) {
            setValidDates({
              validateStatus: "error",
              errorMsg: "End of work cannot be before start of work"
            });
            return false;
          }
        }
      }
    }
    setValidDates({ validateStatus: "success", errorMsg: null });
    return true;
  }

  useEffect(() => {
    checkDates();
  }, [endMonth, endYear]);

  const [createWorkExperience] = useMutation(CREATE_WORK_EXPERIENCE);

  return (
    <Form
      className="login-form"
      onSubmit={e => {
        e.preventDefault();
        if (
          company &&
          position &&
          startMonth !== undefined &&
          startYear !== undefined &&
          checkDates()
        ) {
          
          createWorkExperience({
            variables: {
              input: {
                company,
                position,
                startMonth,
                endMonth,
                startYear,
                endYear
              }
            }
          });
          window.location.reload();
        }
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label={t("position")} required>
            <Input
              required
              placeholder="Please enter position"
              onChange={e => setPosition(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("company")} required>
            <Input
              required
              placeholder="Please enter company"
              onChange={e => setCompany(e.target.value)}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label={t("start_date")} required>
            <DatePicker
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                if (date) {
                  setStartMonth(date.month());
                  setStartYear(date.year());
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("end_date")}
            validateStatus={validDates.validateStatus}
            help={validDates.errorMsg || "Leave blank if still working"}
          >
            <DatePicker
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                if (date) {
                  setEndMonth(date.month());
                  setEndYear(date.year());
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ display: "inline-block" }}
            >
              {t("add_experience")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default withTranslation()(AddWorkExperienceForm);
