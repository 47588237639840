import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { Select } from "antd";

const { Option } = Select;


const GET_JOBS= gql`
  query getJobs {
		currentUser {
			id
			jobs {
                id
				title
			}
		}
	}
`;

export default function JobSelect(props) {
  const { value, onChange } = props;
  const { loading, error, data } = useQuery(GET_JOBS, {
    fetchPolicy: "cache-first"
  });

  let jobs = []
  if (!loading && !error && data.currentUser) {
    jobs = data.currentUser.jobs;
  }

  return (
    <Select
      placeholder="Select job"
      style={{ width: '100%' }}
      size="large"
      value={value}
      onChange={onChange}
    >
     {jobs.map(job=><Option key={job.id} value={job.id}>{job.title}</Option>)}
    </Select>
  );
}