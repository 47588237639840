import React, { useState } from 'react';
import gql from 'graphql-tag';
import moment from 'moment';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { Form, DatePicker, Row, Col, Button, TimePicker, Typography } from 'antd';
import Panel from './Panel/Panel';
import JobSelect from './JobSelect';

const { Text } = Typography;

const CREATE_TIME_LOG_ENTRY = gql`
mutation createTimeLogEntry($newTimeLogEntryInput: NewTimeLogEntryInput!){
	createTimeLogEntry(newTimeLogEntryInput: $newTimeLogEntryInput){
		type: __typename
			... on TimeLogEntry {
				id
			}

			... on TimeLogRegistrationError {
				id
				message
			}
	}
}
`

const TIME_FORMAT = 'HH:mm';

export default function TimeRegistrationForm() {
	const { t } = useTranslation();
	const [selectedDate, setSelectedDate] = useState(moment());
	const [selectedStartTime, setSelectedStartTime] = useState(
		moment()
			.subtract(3, 'hours')
			.seconds(0)
	);
	const [selectedEndTime, setSelectedEndTime] = useState(moment().seconds(0));
	//const [selectedClient, setSelectedClient] = useState();
	const [selectedJob, setSelectedJob] = useState();

	const [createTimeLogEntry, { loading, data }] = useMutation(CREATE_TIME_LOG_ENTRY, {
		refetchQueries: ['getUserTimeLogs'],
	});

	function parseTimeValue(date, time) {
		const timeValue = moment(date);
		timeValue.hours(time.hours());
		timeValue.minutes(time.minutes());
		timeValue.seconds(time.seconds());
		return timeValue;
	}


	let error = null;
	if(data && data.createTimeLogEntry.type === "TimeLogRegistrationError") {
		error = data.createTimeLogEntry.message;
	}


	return (
		<Panel title={t('time_registration')}>
			<Form>
				<Row gutter={24}>
					<Col span={12}>
						<Form.Item label={t('work_item')} colon={false} className={'tb-margin-bottom-medium'}>
						<JobSelect value={selectedJob} onChange={setSelectedJob} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label={t('date')} colon={false} className={'tb-margin-bottom-medium'}>
							<DatePicker
								size="large"
								style={{ width: '100%', position: 'relative' }}
								value={selectedDate}
								onChange={setSelectedDate}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={24}>
					<Col span={12}>
						<Form.Item label={t('start_time')} colon={false} className={'tb-margin-bottom-medium'}>
							<TimePicker
								value={selectedStartTime}
								onChange={setSelectedStartTime}
								defaultOpenValue={moment('00:00:00', TIME_FORMAT)}
								format={TIME_FORMAT}
								size="large"
								style={{ width: '100%' }}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label={t('end_time')} colon={false} className={'tb-margin-bottom-medium'}>
							<TimePicker
								value={selectedEndTime}
								onChange={setSelectedEndTime}
								defaultOpenValue={moment('00:00:00', TIME_FORMAT)}
								format={TIME_FORMAT}
								size="large"
								style={{ width: '100%' }}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row type="flex" align="middle" gutter={24}>
					<Col>
						<Button
							type="primary"
							size="large"
							onClick={() => {
								const actualStartTime = parseTimeValue(selectedDate, selectedStartTime);
								const actualEndTime = parseTimeValue(selectedDate, selectedEndTime);
								if(selectedJob) {	
									console.log("Values: job: " + selectedJob + "start:" + actualStartTime.toString() +"end: " + actualEndTime.toString() )								
									createTimeLogEntry({
										variables: {
											newTimeLogEntryInput : {
												jobID: selectedJob,
												startTime: actualStartTime.toString(),
												endTime: actualEndTime.toString(),
											}											
										},
									});
								}
							}}
							loading={loading}
							disabled={loading}
						>
							{t('submit')}
						</Button>
					</Col>
					{error && (
						<Col>
							<Text type="danger">{error}</Text>
						</Col>
					)}
				</Row>
			</Form>
		</Panel>
	);
}
