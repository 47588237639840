import React from "react";
import {useMutation} from "react-apollo"
import { Timeline, Icon, Typography } from "antd";
import { withTranslation } from 'react-i18next';
import AddStudiesForm from "../AddStudiesForm";
import "./Studies.scss";
import gql from "graphql-tag";
const { Text } = Typography;

export const DELETE_STUDIES = gql`
mutation($id: ID!){
  deleteStudies(id:$id){
    id
  }
}
`

function Studies(props){
  
    const { studiesEntries,t } = props;

    const months = {
      0: t("Jan"),
      1: t("Feb"),
      2: t("Mar"),
      3: t("Apr"),
      4: t("May"),
      5: t("Jun"),
      6: t("Jul"),
      7: t("Aug"),
      8: t("Sep"),
      9: t("Oct"),
      10: t("Nov"),
      11: t("Dic"),
      12: t("Dic") //TODO: Delete this when months are changed in the Database
    };

    const [deleteStudies] = useMutation(DELETE_STUDIES);

    return(
      <Timeline >
        <Timeline.Item
        dot={
          <Icon
            type="edit"
            theme="twoTone"
            twoToneColor="#6d6eed"
            style={{ fontSize: "16px" }}
          />
        }
      >
        <Text strong>{t("new_studies")}</Text>
        <AddStudiesForm />
      </Timeline.Item>
      {studiesEntries.map(studiesEntry=>{
        return(
          
          <Timeline.Item
            dot={<Icon type="star" theme="twoTone" twoToneColor="#6d6eed" />}
            color="green"
            key={studiesEntry.id}
          >
            <Text strong>{studiesEntry.school}</Text><Icon type="delete" title="Delete studies" onClick={()=>{deleteStudies({variables:{id:studiesEntry.id}}); window.location.reload()}} style={{marginLeft:5}}/>
            <br />
            <Text style={{ color: "#6d6eed" }}>
              {studiesEntry.studies}
            </Text>{" "}
            <br />
            <Text className={"tb-margin-bottom-medium"} type="secondary">
            {studiesEntry.startMonth ? months[studiesEntry.startMonth] : ""} {studiesEntry.startYear} -{" "}
                {studiesEntry.endMonth ? months[studiesEntry.endMonth] : ""}{" "}
                {studiesEntry.endYear ? studiesEntry.endYear : t('present')}
            </Text>        
          </Timeline.Item>     
        )        
      })}
      </Timeline>
       
    )
}

export default withTranslation()(Studies);