import React from "react";
import { Type, TextBold, TextSize } from "../../Type/Type";
import ProfileBannerContainer from "../../Profile/Banner/Container";
import Card from "../../UI/Card";

function InfoCard({ user , ...rest}) {
  const { id, firstName, lastName, avatarUrl } = user;

  return (
    <Card>
      <Type size={TextSize.lg} boldness={TextBold.bold}>
        <ProfileBannerContainer
          userID={id}
          firstName={firstName}
          lastName={lastName}
          url={avatarUrl}
          isOwn
          {...rest}
        />
      </Type>
    </Card>
  );
}

export default InfoCard;